@charset "UTF-8";
/* Kolory */
/* Kolory fontow */
/* Kolory tła */
/* Kolory borderow */
/*Breakpointy */
@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes flash {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

@keyframes widthUp {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@keyframes widthDown {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

:root,
:root[data-theme="light"] {
  --white: #ffffff;
  --black: #000000;
  --primaryBg: #ffffff;
  --secondaryBg: #ffffff;
  --thirdBg: #181A1B;
  --grayBg: #E6E6E6;
  --primaryColor: #010101;
  --mainColor: #D80001;
  --primaryBorderColor: #EFEFEF;
  --secondaryBorderColor: #B3B3B3;
  --primary700: #A60001;
  --primary600: #BF0001;
  --primary500: #D80001;
  --primary400: #F21819;
  --primary300: #FF3334;
  --secondary700: #0B66A2;
  --secondary600: #207DBC;
  --secondary500: #3897D6;
  --secondary400: #5CB5F0;
  --secondary300: #7ACAFF;
  --grey900: #2F2F2F;
  --grey700: #4C4C4C;
  --grey500: #7F7F7F;
  --grey300: #B3B3B3;
  --grey100: #E5E5E5;
  --primaryGradient: linear-gradient(45deg, rgba(255, 51, 51, 1) 0%, rgba(214, 0, 0, 1) 100%);
  --chartGradientRed: linear-gradient(0deg, rgba(255, 51, 51, 1) 0%, rgba(214, 0, 0, 1) 100%);
  --chartGradientGrey: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(179, 179, 179, 1) 100%);
  --primaryFont: 'Roboto', Arial, sans-serif;
  --mb: 20px; }
  @media (min-width: 1025px) {
    :root,
    :root[data-theme="light"] {
      --mb: 30px; } }

:root[data-theme="dark"] {
  --primaryBg: #181A1B;
  --secondaryBg: #262626;
  --thirdBg: #262626;
  --grayBg: #262626;
  --primaryColor: #FFFFFF;
  --primaryBorderColor: #464849;
  --mainColor: #FF7C7C;
  --chartGradientGrey: linear-gradient(0deg, rgba(70, 72, 73, 1) 0%, rgba(128, 128, 128, 1) 100%); }

:root[data-theme="light"] [data-theme="dark"] {
  display: none; }

:root[data-theme="dark"] [data-theme="light"] {
  display: none; }

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url("https://cdn.avt.pl/fonts/Roboto-Light.eot");
  src: url("https://cdn.avt.pl/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Roboto-Light.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Roboto-Light.woff") format("woff"), url("https://cdn.avt.pl/fonts/Roboto-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url("https://cdn.avt.pl/fonts/Roboto-Regular.eot");
  src: url("https://cdn.avt.pl/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Roboto-Regular.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Roboto-Regular.ttf") format("ttf"), url("https://cdn.avt.pl/fonts/Roboto-Regular.woff") format("woff"), url("https://cdn.avt.pl/fonts/Roboto-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url("https://cdn.avt.pl/fonts/Roboto-Medium.eot");
  src: url("https://cdn.avt.pl/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Roboto-Medium.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Roboto-Medium.ttf") format("ttf"), url("https://cdn.avt.pl/fonts/Roboto-Medium.woff") format("woff"), url("https://cdn.avt.pl/fonts/Roboto-Medium.svg") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url("https://cdn.avt.pl/fonts/Roboto-Bold.eot");
  src: url("https://cdn.avt.pl/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Roboto-Bold.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Roboto-Bold.ttf") format("ttf"), url("https://cdn.avt.pl/fonts/Roboto-Bold.woff") format("woff"), url("https://cdn.avt.pl/fonts/Roboto-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url("https://cdn.avt.pl/fonts/Roboto-Black.eot");
  src: url("https://cdn.avt.pl/fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Roboto-Black.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Roboto-Black.ttf") format("ttf"), url("https://cdn.avt.pl/fonts/Roboto-Black.woff") format("woff"), url("https://cdn.avt.pl/fonts/Roboto-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  font-display: block;
  src: url("https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  font-display: block;
  src: url("/templates/main/fonts/Icommon/icomoon.eot?4rcf8r");
  src: url("/templates/main/fonts/Icommon/icomoon.eot?4rcf8r#iefix") format("embedded-opentype"), url("/templates/main/fonts/Icommon/icomoon.ttf?4rcf8r") format("truetype"), url("/templates/main/fonts/Icommon/icomoon.woff?4rcf8r") format("woff"), url("/templates/main/fonts/Icommon/icomoon.svg?4rcf8r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa, .search-button:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-google-plus:before {
  content: "\f0d5"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-newspaper-o:before {
  content: "\f1ea"; }

.fa-search:before {
  content: "\f002"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-font:before {
  content: "\f031"; }

.fa-print:before {
  content: "\f02f"; }

.fa-check:before {
  content: "\f00c"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-times:before {
  content: "\f00d"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-clock-o:before {
  content: "\f017"; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-thumb-up:before {
  content: "\e900"; }

.icon-support:before {
  content: "\e901"; }

.icon-quality:before {
  content: "\e902"; }

.icon-low-prices:before {
  content: "\e903"; }

.icon-info:before {
  content: "\e904"; }

.icon-delivery-time:before {
  content: "\e905"; }

html, body {
  height: 100%; }

body {
  margin: 0; }

* {
  box-sizing: border-box; }

::selection {
  background: rgba(216, 0, 0, 0.75); }

.error-main {
  font-family: "Roboto", sans-serif; }

.error-header {
  height: 100px;
  background-color: #fff;
  position: relative; }
  .error-header .container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #000;
    max-width: 1500px;
    margin: 0 auto; }
  .error-header .logo {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    transform: translateY(-50%);
    width: 260px; }
    .error-header .logo:after {
      content: "";
      position: absolute;
      height: 2px;
      background-color: #000; }
    .error-header .logo-box {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }

.error-main {
  width: 100%;
  background-image: url("../../images/404.png");
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: contain; }
  @media (min-width: 576px) {
    .error-main {
      background-position: center 25%;
      background-size: auto; } }
  .error-main .content-wrapper {
    margin: 0 auto;
    color: #000;
    text-align: center; }
    .error-main .content-wrapper .top {
      min-height: calc(100vh - 100px);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: start;
      align-content: start;
      padding-top: 75px; }
    .error-main .content-wrapper a {
      color: inherit;
      font-weight: 700;
      transition: color .25s ease-in;
      text-decoration: underline; }
      .error-main .content-wrapper a:hover {
        color: #D80000; }

.error-message, .debug-error {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  width: 100%; }
  @media (min-width: 576px) {
    .error-message, .debug-error {
      font-size: 30px; } }
  @media (min-width: 1366px) {
    .error-message, .debug-error {
      font-size: 40px; } }

.error-hint, .search-input {
  font-size: 16px;
  font-weight: 500;
  width: 100%; }
  @media (min-width: 576px) {
    .error-hint, .search-input {
      font-size: 25px; } }

.error-hint, .search-input {
  margin-bottom: 30px; }

.error-code {
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  color: #F2F2F2;
  padding: 0 30px;
  display: inline-block;
  margin-bottom: 5px; }
  @media (min-width: 576px) {
    .error-code {
      font-size: 200px; } }
  @media (min-width: 1280px) {
    .error-code {
      font-size: 210px; } }
  @media (min-width: 1366px) {
    .error-code {
      font-size: 240px; } }

.search {
  width: 100%; }
  .search-form {
    width: 100%;
    max-width: 700px;
    display: inline-block;
    position: relative; }
  .search-input {
    margin-bottom: 20px;
    background-color: #fff;
    color: #000;
    border: 1px solid #AFAFAF;
    width: 100%;
    max-width: 700px;
    height: 65px;
    font-size: 25px;
    padding: 0 73px 0 10px; }
    @media (min-width: 576px) {
      .search-input {
        margin-bottom: 30px; } }
    @media (min-width: 768px) {
      .search-input {
        margin-bottom: 30px; } }
    @media (min-width: 1025px) {
      .search-input {
        margin-bottom: 30px; } }
    @media (min-width: 1280px) {
      .search-input {
        margin-bottom: 30px; } }
  .search-button {
    width: 63px;
    height: 63px;
    position: absolute;
    top: 1px;
    right: 1px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color .25s ease-in;
    border: none;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .search-button:before {
      content: "\f002"; }
    @media (min-width: 576px) {
      .search-button {
        font-size: 16px; } }
    @media (min-width: 768px) {
      .search-button {
        font-size: 16px; } }
    @media (min-width: 1025px) {
      .search-button {
        font-size: 16px; } }
    @media (min-width: 1280px) {
      .search-button {
        font-size: 16px; } }
    @media (min-width: 1366px) {
      .search-button {
        font-size: 20px; } }
    .search-button:hover, .search-button:focus {
      cursor: pointer; }

.debug {
  margin-top: 30px;
  text-align: left;
  overflow-x: auto;
  background-color: #AFAFAF;
  padding: 30px 0; }
  .debug .table {
    margin: 0 auto; }
    .debug .table td {
      padding: 2px 5px; }
    .debug .table tr:hover {
      background: rgba(255, 255, 255, 0.25); }
  .debug-error {
    text-align: center; }
    .debug-error-code {
      font-weight: 700; }

.error-message, .debug-error {
  font-size: 40px;
  line-height: 42px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #3A3A3A;
  opacity: 0.7; }

.error-hint, .search-input {
  color: #3A3A3A;
  font-size: 20px;
  font-weight: 300;
  opacity: 0.7;
  margin-bottom: 130px; }

.search-input {
  opacity: 1; }
