/* Kolory */

$color-black-0:     		#000;
$color-black-13:    		#131313;
$color-white:				#fff;
$color-category:			#D80000;
$color-gray:				#AFAFAF;
$color-gray-dark:			#5A5A5A;
$color-gray-dark-second:	#3A3A3A;
$color-red: 				#D80000;

$font-main: 'Roboto', Arial, sans-serif;
$font-second: 'Roboto', Arial, sans-serif;
$font-playfair: 'Roboto', Arial, sans-serif;

/* Kolory fontow */
$color-font-main:                   $color-black-0;

/* Kolory tła */
$bg-container:						$color-white;
$bg-footer:							$color-black-13;

/* Kolory borderow */
$color-border-gray:					$color-gray;
$color-border-gray-dark:			$color-gray-dark;


/*Breakpointy */
$xs-max:    575.98px;
$sm:        576px;
$sm-max:    767.98px;
$md:        768px;
$md-max:    1024.98px;
$lg:        1025px;
$lg-max:    1279.98px;
$xl:        1280px;

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

@keyframes widthUp {
    0%      { width: 0%; }
    100%    { width: 100%; }
}
@keyframes widthDown {
	0%		{ width: 100%; }
	100%	{ width: 0%; }
}


:root,
:root[data-theme="light"] {
	// Colors
	--white: #ffffff;
	--black: #000000;

	--primaryBg: #ffffff;
	--secondaryBg: #ffffff;
	--thirdBg: #181A1B;
	--grayBg: #E6E6E6;

	--primaryColor: #010101;
	--mainColor: #D80001;

	--primaryBorderColor: #EFEFEF;
	--secondaryBorderColor: #B3B3B3;

	--primary700: #A60001;
	--primary600: #BF0001;
	--primary500: #D80001;
	--primary400: #F21819;
	--primary300: #FF3334;

	--secondary700: #0B66A2;
	--secondary600: #207DBC;
	--secondary500: #3897D6;
	--secondary400: #5CB5F0;
	--secondary300: #7ACAFF;

	--grey900: #2F2F2F;
	--grey700: #4C4C4C;
	--grey500: #7F7F7F;
	--grey300: #B3B3B3;
	--grey100: #E5E5E5;

	--primaryGradient: linear-gradient(45deg, rgba(255, 51, 51, 1) 0%, rgba(214, 0, 0, 1) 100%);

	--chartGradientRed: linear-gradient(0deg, rgba(255, 51, 51, 1) 0%, rgba(214, 0, 0, 1) 100%);
	--chartGradientGrey: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(179, 179, 179, 1) 100%);

	// Fonts
	--primaryFont: 'Roboto', Arial, sans-serif;

	@media(min-width: 1025px) {
		--mb: 30px;
	}
	--mb: 20px;
}


:root[data-theme="dark"] {
	--primaryBg: #181A1B;
	--secondaryBg: #262626;
	--thirdBg: #262626;
	--grayBg: #262626;

	--primaryColor: #FFFFFF;

	--primaryBorderColor: #464849;

	--mainColor: #FF7C7C;

	--chartGradientGrey: linear-gradient(0deg, rgba(70, 72, 73, 1) 0%, rgba(128, 128, 128, 1) 100%);
}

:root[data-theme="light"] {
	[data-theme="dark"] {
		display: none;
	}
}
:root[data-theme="dark"] {
	[data-theme="light"] {
		display: none;
	}
}