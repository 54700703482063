// Light Roboto

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url('https://cdn.avt.pl/fonts/Roboto-Light.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Light.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Light.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

// Regular Roboto


@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url('https://cdn.avt.pl/fonts/Roboto-Regular.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url('https://cdn.avt.pl/fonts/Roboto-Medium.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}


// Bold Roboto


@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url('https://cdn.avt.pl/fonts/Roboto-Bold.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  font-display: block;
  src: url('https://cdn.avt.pl/fonts/Roboto-Black.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.svg') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
    font-family: 'FontAwesome';
    font-display: block;
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  font-display: block;
  src:  url('/templates/main/fonts/Icommon/icomoon.eot?4rcf8r');
  src:  url('/templates/main/fonts/Icommon/icomoon.eot?4rcf8r#iefix') format('embedded-opentype'),
    url('/templates/main/fonts/Icommon/icomoon.ttf?4rcf8r') format('truetype'),
    url('/templates/main/fonts/Icommon/icomoon.woff?4rcf8r') format('woff'),
    url('/templates/main/fonts/Icommon/icomoon.svg?4rcf8r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-facebook:before           { content: "\f09a"; }
.fa-facebook-square:before    { content: "\f082"; }
.fa-pinterest:before          { content: "\f0d2"; }
.fa-instagram:before          { content: "\f16d"; }
.fa-google-plus:before        { content: "\f0d5"; }
.fa-shopping-cart:before      { content: "\f07a"; }
.fa-newspaper-o:before        { content: "\f1ea"; }
.fa-search:before             { content: "\f002"; }
.fa-link:before               { content: "\f0c1"; }
.fa-font:before               { content: "\f031"; }
.fa-print:before              { content: "\f02f"; }
.fa-check:before              { content: "\f00c"; }
.fa-chevron-right:before      { content: "\f054"; }
.fa-chevron-left:before       { content: "\f053"; }
.fa-times:before              { content: "\f00d"; }
.fa-chevron-down:before       { content: "\f078"; }
.fa-times-circle:before       { content: "\f057"; }
.fa-clock-o:before            { content: "\f017"; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-thumb-up:before      { content: "\e900"; }
.icon-support:before       { content: "\e901"; }
.icon-quality:before       { content: "\e902"; }
.icon-low-prices:before    { content: "\e903"; }
.icon-info:before          { content: "\e904"; }
.icon-delivery-time:before { content: "\e905"; }
