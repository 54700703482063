@import 'abstracts/variables';
@import 'base/typography';


html, body { height: 100%; }
body       { margin: 0; }
* {
    box-sizing: border-box;
}
::selection { background: rgba($color: $color-red, $alpha: 0.75); }
.error-main {
    font-family: "Roboto", sans-serif;
}
.error {
    &-header {
        height: 100px;
        background-color: #fff;
        position: relative;
        .container-fluid {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 100%;
            border-bottom: 2px solid $color-black-0;
            max-width: 1500px;
            margin: 0 auto;
        }
        .logo {
            position: absolute;
            top: 50%;
            display: block;
            width: 100%;
            transform: translateY(-50%);
            width: 260px;
            &:after {
                content: "";
                position: absolute;
                height: 2px;
                background-color: $color-black-0;

            }
            &-box {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &-main {
        width: 100%;
        background-image: url('../../images/404.png');
        background-repeat: no-repeat;
        background-position: center 20%;
        background-size: contain;
        @media(min-width: $sm) {
            background-position: center 25%;
            background-size: auto;
        }
        .content-wrapper {
            margin: 0 auto;
            color: $color-font-main;
            text-align: center;
            .top {
                min-height: calc(100vh - 100px);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: start;
                align-content: start;
                padding-top: 75px;
            }
            a {
                color: inherit;
                font-weight: 700;
                transition: color .25s ease-in;
                text-decoration: underline;
                &:hover { color: $color-red; }
            }
        }
    }

    &-message {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
        width: 100%;
        @media(min-width: $sm) {
            font-size: 30px;
        }
        @media (min-width: 1366px)  {
            font-size: 40px;
        }
    }
    &-hint {
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        @media (min-width: $sm) {
            font-size: 25px;
        }
    }

    &-hint { margin-bottom: 30px; }

    &-code {
        font-size: 100px;
        font-weight: 700;
        line-height: 1;
        // background-color: $color-black-0;
        color: #F2F2F2;
        padding: 0 30px;
        display: inline-block;
        margin-bottom: 5px;
        @media (min-width: $sm)        { font-size: 200px; }
        @media (min-width: $xl) { font-size: 210px; }
        @media (min-width: 1366px)                        { font-size: 240px; }
    }
}

.search {
    width: 100%;
    &-form {
        width: 100%;
        max-width: 700px;
        display: inline-block;
        position: relative;
    }
    &-input {
        @extend .error-hint;
        margin-bottom: 20px;
        background-color: $color-white;
        color: $color-font-main;
        border: 1px solid $color-border-gray;
        width: 100%;
        max-width: 700px;
        height: 65px;
        font-size: 25px;
        padding: 0 73px 0 10px;
        @media (min-width: $sm)        { margin-bottom: 30px; }
        @media (min-width: $md)        { margin-bottom: 30px; }
        @media (min-width: $lg)        { margin-bottom: 30px; }
        @media (min-width: $xl)        { margin-bottom: 30px; }
    }

    &-button {
        width: 63px;
        height: 63px;
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: $color-white;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        transition: background-color .25s ease-in;
        border: none;
        color: $color-font-main;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        &:before {
            @extend .fa;
            content: "\f002";
        }
        @media (min-width: $sm) {
            font-size: 16px;
        }

        @media (min-width: $md) {
            font-size: 16px;
        }

        @media (min-width: $lg) {
            font-size: 16px;
        }

        @media (min-width: $xl) {
            font-size: 16px;
        }

        @media (min-width: 1366px) {
            font-size: 20px;
        }

        &:hover,
        &:focus {
            cursor: pointer;

        }
    }
}

.debug {
    margin-top: 30px;
    text-align: left;
    overflow-x: auto;
    background-color: $color-gray;
    padding: 30px 0;
    .table {
        margin: 0 auto;
        td       { padding: 2px 5px; }
        tr:hover { background: rgba($color: #fff, $alpha: 0.25); }
    }

    &-error {
        @extend .error-message;
        text-align: center;
        &-code { font-weight: 700; }
    }
}
.error-message {
    font-size: 40px;
    line-height: 42px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #3A3A3A;
    opacity: 0.7;
}
.error-hint {
    color: #3A3A3A;
    font-size: 20px;
    font-weight: 300;
    opacity: 0.7;
    margin-bottom: 130px;
}
.search-input {
    opacity: 1;
}
